import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsHero from "../components/projectstHero";
import ProjectList from "../components/projectList";

const ProjectsPage = () => (
  <Layout>
    <SEO title="My Projects" />
    <ProjectsHero></ProjectsHero>
    <ProjectList></ProjectList>
  </Layout>
)

export default ProjectsPage
