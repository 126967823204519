import React from "react"
import heroStyles from "./hero.module.scss"
import TrimmedContainer from "./trimmed-container";

const ProjectsHero = () => {
    return (
        <div className={heroStyles.wall}>
            <TrimmedContainer>
                <section className={heroStyles.container}>
                    <div className={heroStyles.lead}>
                        <h1 className={heroStyles.myName}>Projects</h1>

                        <h2 className={heroStyles.myPurpose}>A showcase of my featured work.</h2>
                    </div>
                </section>
            </TrimmedContainer>
            <div className={heroStyles.fullpageDivider}></div>
        </div>
    )
}

export default ProjectsHero;