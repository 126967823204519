import React from "react"
import styles from "./projectList.module.scss"
import TrimmedContainer from "./trimmed-container";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import FitLogo from "../images/fitlogo.png";
import FramealotLogo from "../assets/svgs/framealotLogo.inline.svg";
import OpenLayersIco from "../assets/images/openlayersIco.png";
import DapperOrm from "../assets/images/dapperorm.png";
import ASPCore from "../assets/svgs/NET_Core_Logo.svg";
import StencilJS from "../assets/svgs/stencil.svg";
import MariaDB from "../assets/svgs/mariadb-icon.svg";
import RxJS from "../assets/svgs/reactivex.svg";
import Jest from "../assets/svgs/jest.svg";
import Jenkins from "../assets/svgs/jenkins.svg";
import Dotnet from "../assets/svgs/dotnet.svg";
import Angular from "../assets/svgs/angular-icon.svg";
import Jasmine from "../assets/svgs/jasmine.svg";
import Ngrx from "../assets/svgs/ngrxbadge.svg";
import SQLServer from "../assets/svgs/mssql.svg";
import SQLlite from "../assets/svgs/Sqlite-square-icon.svg";

const ProjectList = () => {

    const data = useStaticQuery(graphql`
      query {
        framebuilderImage: file(relativePath: { eq: "Framealot_framebuilder.png" }) {
          childImageSharp {
            fluid(maxWidth: 1155, traceSVG: {  color: "#035c96" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        },
        framezoomImage: file(relativePath: { eq: "Framealot_framezoom.png" }) {
            childImageSharp {
                fluid(maxWidth: 1155, traceSVG: {  color: "#035c96" }) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        },
        geoFarmImage: file(relativePath: { eq: "GeoFarm.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 785, quality: 100, traceSVG: {  color: "#035c96" }) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
        },
        geoLisImage: file(relativePath: { eq: "geoLIS.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 785, traceSVG: {  color: "#035c96" }) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
        }
      }
    `);


    return (
        <section className={styles.container}>
            <TrimmedContainer>
                <div className={styles.subContainer}>

                    <div className={styles.project}>

                        <div className={styles.framealotLogo}>
                            <a href="https://www.framealot.com" target="blank">
                                <FramealotLogo />
                            </a>
                        </div>

                        <div>
                            <a href="https://www.framealot.com" target="blank">
                                <div className={styles.bannerContainer}>
                                    <span className={styles.bannerFarmeTxt}>FRAME</span>
                                    <span className={styles.bannerAlotTxt}>ALOT</span>
                                </div>
                                <span className={styles.bannerSlogan}>Online custom framing made easy</span>
                                <div className={styles.projectLink}>https://www.framealot.com</div>
                            </a>

                            <div className={styles.projectAbout}>

                                <p>
                                    I love to build things! I discovered Picture Framing in 2004. Since then, I have framed countless artwork, certificates, medals, just about anything you can imagine.<br />
                                    I quickly realized that I could make my life easier by developing an application to help estimate the cost for framing. And so, a passion project was born. Today, Framealot is a full-fledged eCommerce application.
                           </p>

                                <p>
                                    I had a very specific idea of what I wanted to accomplish. After my initial planning, I went through various frameworks such as Magento, NopCommerce and Reaction commerce. I took a bold step and decided to build my own eCommerce platform from scratch. <span role="img" aria-label="Shocked">😲</span>
                                </p>

                                <p>
                                    I took account of what I have learned as a full-stack developer and selected the tools that will help me achieve my goal:
                            </p>

                                <div className={styles.stackList}>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={StencilJS} loading="lazy" alt="StencilJS" />
                                        </figure>
                                        <p>StencilJS to build standards based Web Components that run in every browser.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={RxJS} loading="lazy" alt="RxJS"/>
                                        </figure>
                                        <p>Reactive Extensions to manage data flow throughout the application.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={Jest} loading="lazy" alt="Jest"/>
                                        </figure>
                                        <p>Jest for unit testing my components together with Puppeteer for E2E tests.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={ASPCore} loading="lazy" alt="ASPCore"/>
                                        </figure>
                                        <p>ASP.NET Core and EF Core for the backend services.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={MariaDB} loading="lazy" alt="MariaDB"/>
                                        </figure>
                                        <p>Used MariaDB as a fast, secure and scalable database. </p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={Jenkins} loading="lazy" alt="Jenkins"/>
                                        </figure>
                                        <p>I used Jenkins to automate my builds to my UAT environments and set up continuous delivery to my Production servers.</p>
                                    </div>


                                </div>



                            </div>
                        </div>

                    </div>

                    <div className={styles.projectImagesContainer}>

                        <p>
                            What makes this project fun is the Framebuilder, where a user can pick and choose the elements that make up a frame. When done, you can zoom in and get a larger view of your design.
                        </p>

                        <div>
                            <a href="https://www.framealot.com/certificate-silver-frame" target="blank">
                                <Img alt="Framealot framebuilder" fluid={data.framebuilderImage.childImageSharp.fluid} />
                            </a>

                            <a href="https://www.framealot.com/certificate-silver-frame" target="blank">
                                <Img alt="Framealot framezoom" fluid={data.framezoomImage.childImageSharp.fluid} />
                            </a>
                        </div>

                    </div>
                </div>
            </TrimmedContainer>


            <div className={styles.fullpageDivider}></div>


            <TrimmedContainer>

                <div className={styles.subContainer}>

                    <div className={styles.project}>

                        <div className={styles.farmitLogo}>
                            <img src={FitLogo} alt="FarmIT logo" />
                        </div>

                        <div>

                            <div className={styles.fitbanner}>
                                Farm-IT
                            </div>


                            <div className={styles.projectAbout}>

                                <p>
                                    I have developed a number of desktop and web applications for the agricultural sector at Farm-IT, where I was lucky enough to use any technology stack to solve the business problems I had before me. This has given me ample experience in choosing the right tech to solve problems. I soon learned that choosing your own stack is a big responsibility, as it has profound effects on the life cycle of a project.
                                </p>

                                <p>
                                    I enjoy designing meaningful UX/UI so that users can solve complex problems using a simple and intuitive interface. I am a strong believer in less is more, which translates to less work for the end user when engaging with my apps.
                                </p>

                                <p>
                                    From the outset, my focus was on creating application experiences that would work as well offline as online. I incorporated the ideas of progressive web apps in 2012 before they became popular.
                                </p>

                                <h3>
                                    GeoFarm
                                </h3>
                                <p>
                                    GeoFarm is a single-page application (SPA) for agronomist that manages input budgets and actual expenditure.
                                </p>

                                <div className={styles.stackList}>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={Angular} loading="lazy" alt="Angular"/>
                                        </figure>
                                        <p>Originally AngularJS was used for the frontend. Upgraded later, to Angular 4.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={Ngrx} loading="lazy" alt="Ngrx"/>
                                        </figure>
                                        <p>Used NgRx Store to provide reactive state management for the application.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={OpenLayersIco} loading="lazy" alt="Openlayers" />
                                        </figure>
                                        <p>Openlayers was used to display vector and raster data from a GeoServer endpoint.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={Jasmine} loading="lazy" alt="Jasmine" />
                                        </figure>
                                        <p>Jasmine and Karma test runner for unit testing components.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={Dotnet} loading="lazy" alt="Dotnet"/>
                                        </figure>
                                        <p>For the backend services, I went with .NET 4.5 ASP.NET Web API.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={SQLServer} loading="lazy" alt="SQLServer"/>
                                        </figure>
                                        <p>MS SQL Server for the database.</p>
                                    </div>


                                </div>

                                <h3>
                                    GeoLIS
                                </h3>
                                <p>
                                    GeoLIS is a GIS-based application used daily by silo managers to assist operations. This is one of my first enterprise projects that I completed in 2009, while still being a junior developer.

                                </p>
                                <p>
                                    I'm very proud to say that GeoLIS is still used in production today.
                                    {/* ......This is a windows application build with WPF and C# with a local sqlite db that synchronizes with SQL Server that makes it possible to work offline. */}
                                </p>


                                <div className={styles.stackList}>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={Dotnet} loading="lazy" alt="Dotnet"/>
                                        </figure>
                                        <p>This is a windows application build with WPF using a MVVM pattern.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={DapperOrm} loading="lazy" alt="DapperOrm"/>
                                        </figure>
                                        <p>Dapper was used as the Object-Relational-Mapper for the application.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={SQLlite} loading="lazy" alt="SQLlite"/>
                                        </figure>
                                        <p>SQLite was used for local persistance and syncronised to a MS SQL Server database.</p>
                                    </div>

                                    <div className={styles.stackInfo}>
                                        <figure className={styles.projectLogo}>
                                            <img src={SQLServer} loading="lazy" alt="SQLServer"/>
                                        </figure>
                                        <p>Reporting was done using MS SQL Server Reporting Services.</p>
                                    </div>


                                </div>

                            </div>
                        </div>

                    </div>

                    <div className={styles.projectImagesContainer}>

                        <p>
                            Links to these projects are unfortunately not publically available.
                        </p>

                        <div className={styles.imagesContainerMargin}>
                            <div>
                                <Img alt="GeoFarm UI" fluid={data.geoFarmImage.childImageSharp.fluid} />
                                <figcaption>GeoFarm</figcaption>
                            </div>
                            <div>
                                <Img alt="GeoLIS UI" fluid={data.geoLisImage.childImageSharp.fluid} />
                                <figcaption>GeoLIS</figcaption>
                            </div>
                        </div>

                    </div>
                </div>

            </TrimmedContainer>
        </section>

    );
};

export default ProjectList;